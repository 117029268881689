<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent
      v-if="model?.elements?.image?.value?.length > 0"
      :id="model?.system?.id"
      class="carousel-item"
      :class="{ active: isActive }"
      :component="!model?.system?.workflow"
    >
      <div ref="bgContainer" class="hero-background-container">
        <picture v-if="index == 0">
          <source
            v-for="(image, i) in lqipImages"
            :key="`${model?.system?.id}-src-${i}`"
            :srcset="image"
            :media="lqipImagesMedia[i]"
          />
          <NuxtImg :src="lqipImageDefault" class="hero-background-lqip" />
        </picture>
        <NuxtImg
          v-if="reactiveImageUrl.length > 0"
          :src="reactiveImageUrl"
          loading="lazy"
          class="hero-background-image"
        />
        <div class="home-hero__wrapper hero-background-overlay">
          <div class="container">
            <div class="col">
              <div :class="`caption border-top border-5 border-manatt-${captionBorderColor}`">
                <h2 class="title">
                  {{ model?.elements.linkedin.value }}
                </h2>
                <div class="description">
                  <RichText :value="model?.elements.content.value" />
                </div>
                <div class="cta">
                  <CallToAction
                    v-for="cta in pageStore.getListAs<CallToActionModel>(
                      model?.elements.callToAction.value
                    )"
                    :key="cta?.system?.id"
                    :model="cta"
                    context-class="btn btn-manatt-black"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import type { CallToActionModel, HomeHeroSlideModel, InteriorHeroSlideModel } from '~/models'
import { useElementSize } from '@vueuse/core'

const props = defineProps<{
  model: HomeHeroSlideModel | InteriorHeroSlideModel
  isActive: boolean
  index: number
}>()
const pageStore = usePageStore()

const bgContainer = ref<HTMLElement>()
const { width, height } = useElementSize(bgContainer)
const { reactiveImageUrl } = useImageSourceReactive(
  props.model?.elements?.image?.value[0]?.url,
  width,
  height
)

const {
  imageDefault: lqipImageDefault,
  images: lqipImages,
  imagesMedia: lqipImagesMedia
} = useImageSourceset(props.model?.elements?.image?.value[0]?.url, 0, 600, true)

if (props.index == 0) {
  useHead({
    link: [
      ...(lqipImages.value.map((image) => {
        return {
          rel: 'preload',
          href: image,
          as: 'image',
          fetchpriority: 'high',
          media: lqipImagesMedia.value[lqipImages.value.indexOf(image)]
        }
      }) as Array<Record<string, any>>)
    ]
  })
}

const themeColor = pageStore.getThemeColor()
const captionBorderColor = themeColor == 'black' ? 'gold' : themeColor

onMounted(() => {})
</script>
